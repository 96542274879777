<template>
  <div>
    <Header />
    <pageTitle :txt="ttl" :backBtn="backBtn" />
    <div class="meta-blk">
      <div class="meta-date">{{ printDepartureDate }}</div>
      <div class="meta-txt">出荷分</div>
      <div class="meta-label">
        <span v-if="this.isDecision">確定</span>
        <span v-else>{{ today | dateFormatShort }}時点暫定</span>
      </div>

      <div
        @click="(print = !print), ((overlay = !overlay), setPrint())"
        class="print-btn"
      >
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list-shop" v-for="row in this.pageNum" :key="row">
        <li class="list-head">
          <ul class="product">
            <li class="date"></li>
            <li class="box">箱数</li>
            <li class="vacuum"></li>
            <li class="product-item" v-for="product in items" :key="product.id">
              {{ product.name }}
            </li>
          </ul>
        </li>
        <instructionsDetailShopItem
          v-for="(shop, key) in stores.slice(
            rowNum * row - rowNum,
            rowNum * row
          )"
          :key="key"
          :items="shop.items"
          class="list-item"
          :name="shop.store_name"
          date="23"
          :day="0"
          :boxNum="shop.boxNum"
          dayName="月祝"
          :time="shop.send_timing"
        />
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div v-if="print">
        <div
          class="print-paper portrait"
          v-for="paper in this.printNum"
          :key="paper"
        >
          <div class="print-inner">
            <div class="meta-blk">
              <div class="meta-date">{{ printDepartureDate }}</div>
              <div class="meta-txt">出荷分</div>
              <div class="meta-label">
                <span v-if="isDecision">確定</span>
                <span v-else>{{ today | dateFormatShort }}時点暫定</span>
              </div>
            </div>

            <ul class="list-shop">
              <li class="list-head">
                <ul class="product">
                  <li class="date"></li>
                  <li class="box">箱数</li>
                  <li class="vacuum"></li>
                  <li
                    class="product-item"
                    v-for="product in items"
                    :key="product.id"
                  >
                    {{ product.name }}
                  </li>
                </ul>
              </li>
              <instructionsDetailShopItemPrint
                v-for="(shop, key) in stores.slice(
                  dataPerRow * paper - dataPerRow,
                  dataPerRow * paper
                )"
                :key="key"
                :items="shop.items"
                class="list-item"
                :name="shop.store_name"
                :day="0"
                :orderNum="shop.total_box_num"
                :time="shop.send_timing"
                :dataPerPage="dataPerPage"
                :index="paper"
                :pagePerItems="pagePerItems"
                :boxNum="shop.boxNum"
              />
            </ul>
          </div>
        </div>
      </div>

      <div id="fixedMenu" v-if="print">
        <div
          v-on:click="(print = !print), (overlay = !overlay), removeSetPrint()"
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

.meta-blk {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 10px;
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}

.meta-txt {
  @extend .f_pageTtl;
  margin-right: 20px;
}

.meta-label {
  font-size: 1.5rem;
  letter-spacing: 0.06em;
  box-sizing: border-box;
  padding: 0 15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px variables.$txt-default;
}

.meta-box {
  @extend .f_pageTtl;
  margin-left: 70px;
}

.meta-box__num {
  font-size: 2rem;
  display: inline-block;
  margin: 0 4px 0 7px;
}

.print-btn {
  margin-left: auto;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list-shop {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  //border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  min-width: 1281px;
  margin: 0 auto 50px;

  .list-head {
    width: 160px;
    min-width: 160px;
  }

  .date {
    height: 90px;
    box-sizing: border-box;
    border-right: solid 1px variables.$bg-gray-2;
    border-bottom: solid 1px variables.$bg-gray-2;
  }

  .box {
    height: 30px;
    border-right: solid 1px variables.$bg-gray-2;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 15px;
    @extend .f_body;
  }

  .vacuum {
    height: 30px;
    box-sizing: border-box;
    border-right: solid 1px variables.$bg-gray-2;
  }

  .product-item {
    border-right: solid 1px variables.$bg-gray-2;
    border-bottom: solid 1px variables.$bg-gray-2;
    box-sizing: border-box;
    padding: 0 15px;
    height: 60px;
    display: flex;
    align-items: center;
    &:nth-of-type(odd) {
      background: variables.$bg-gray-1;
    }
  }

  .list-head,
  .list-item {
    margin-bottom: 40px;
  }
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.print-paper {
  .meta-blk,
  .list-shop {
    transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
    //min-width: 1650px;//横向きの場合
    min-width: 1060px; //縦向きの場合
  }

  .date {
    height: 70px;
  }
  .list-shop {
    height: 60px;
    border-left: none;
  }
  .meta-blk {
    height: 40px;
  }
  .col-name {
    width: 155px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-note {
    width: 90px;
  }
  .product-item {
    height: 40px;
  }
}

.print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .list-all {
    box-sizing: border-box;
    @extend .f_body;
    display: flex;
    flex-wrap: wrap;
    width: 1280px;
    margin: 0 auto 20px;
    .list-item {
      width: 200px;
      height: 120px;
      position: relative;
      box-sizing: border-box;
      border: solid 1px variables.$bg-gray-2;
      margin: 0 15px 20px 0;
      &:nth-of-type(6n) {
        margin-right: 0;
      }
    }

    .data-name {
      @extend .f_body;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .data-blk {
      height: 88px;
      background: variables.$bg-gray-1;
      display: flex;
      padding: 10px 15px 20px;
      box-sizing: border-box;
      .num {
        font-size: 2.4rem;
        letter-spacing: 0.05em;
        margin-right: 2px;
        white-space: nowrap;
      }
      .unit {
        @extend .f_body;
        position: relative;
        top: -4px;
      }
    }
  }

  .data-quantity {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
  }

  .data-vacuum {
    color: variables.$scrollbar-back;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
  }

  .vacuum-txt {
    @extend .f_body;
    width: 100%;
    text-align: right;
  }
}

.print-meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}
#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import instructionsDetailShopItem from "@/components/iseya/instructionsDetailShopItem";
import instructionsDetailShopItemPrint from "@/components/iseya/instructionsDetailShopItemPrint";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "instructionsDetailShop",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    instructionsDetailShopItem,
    instructionsDetailShopItemPrint
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      load: false,
      rowNum: 7, //店舗数 / カラム数 web用
      dataPerRow: 7, //1ページあたりのカラム数 印刷用
      dataPerPage: 35, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      pageNum: 1, //ページ数 web用
      printNum: 1, //印刷枚数 = 店舗数dataPerRow
      weekday: "",
      thisDay: "",
      isDecision: false,
      stores: [],
      items: [],
      ttl: "発送指示書",
      printDepartureDate: ""
    };
  },
  watch: {
    todaysLock: function(newV, oldV) {
      console.log("watch : " + newV, oldV);
      if (newV) {
        this.setDecision(newV);
      }
    }
  },
  methods: {
    setDecision: function(e) {
      console.log("setDecision Run");
      console.log({ e });
      this.today = new Date();
      const selectDate = new Date(this.$route.query.date);
      //this.isDecision = selectDate.getTime() <= this.today.getTime();
      // 1日前&&ロック || 2日以上前は確定表示
      let d =
        (this.calcableDate(selectDate) - this.calcableDate(this.today)) /
        86400000;

      if (d == 1 && this.todaysLock) {
        this.isDecision = true;
        console.log("d == 1 && this.todaysLock");
      } else if (d == 1) {
        this.isDecision = false;
        console.log("d == 1");
      }
      //1日後より前 d = 0以下
      if (d <= 0) {
        this.isDecision = true;
        console.log("d <= 0");
      }
      //2日以上先 d = 2以上
      if (d >= 2) {
        this.isDecision = false;
        console.log("d >= 2");
      }

      console.log("setDecision finish");
    },
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-portrait");
      document.body.classList.add("print-portrait");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-portrait");
      document.body.classList.remove("print-portrait");
    }
  },
  async created() {
    this.setDecision(this.todaysLock);

    const param = {
      date: this.$route.query.date
    };
    const api = new ApiClient();
    const result = await api.post("/iseya/instruction/shop", param);
    this.stores = result.list.filter(item => item.boxNum != 0);
    this.items = result.items;
    this.pageNum = Math.ceil(this.stores.length / this.rowNum);
    this.printNum = Math.ceil(this.stores.length / this.dataPerRow);
    this.pagePerItems = this.items.length / this.dataPerPage;
    this.printDepartureDate = this.ymdJp(new Date(this.$route.query.date));

    console.log("this.isDecision : " + this.isDecision);
    console.log("this.todaysLock : " + this.todaysLock);
  },
  computed: {
    todaysLock: {
      get() {
        return this.$store.state.todaysLock;
      },
      set(newV) {
        this.todaysLock = newV;
      }
    }
  }
};
</script>
